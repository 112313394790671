@media only screen and (min-width: 320px) {
  .body-content {
    margin-top: 10px;
    font-size: 17px;
    line-height: 25px;
  }

  .body-content h1.title {
    font-size: 40px;
    line-height: 46px;
    font-weight: bold;
    font-family: "RalewayBold";
  }

  .body-content h2 {
    font-size: 27px;
    line-height: 35px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: "RalewayBold";
    text-decoration: underline;
  }

  .body-content h3.subtitle {
    font-size: 20px;
    line-height: 28px;
    color: #636363;
    margin: 10px 10px;
    font-style: italic;
    font-family: "RalewayItalic";
  }

  ul.list-link li {
    padding: 4px 0;
    font-weight: bold;
  }

  .link-header a {
    text-decoration: none;
    color: #d33656;
    font-weight: bold;
  }

  .link-header a:hover {
    text-decoration: underline;
    color: #edabb8;
    transition: 0.3s;
  }

  .list-link a {
    text-decoration: none;
    color: #333333;
  }

  .list-link a:visited {
    color: #333333;
  }

  .list-link a:hover {
    text-decoration: underline;
    color: #ccc;
    transition: 0.3s;
  }

  .link-title {
    border: 1px solid gold;
  }

  .top-spacer {
    margin-top: 30px;
  }

  #articles button {
    border: 0;
    color: blue;
    background-color: transparent;
    margin: 0;
    padding: 0;
    font-weight: bold;
  }
}

@media only screen and (min-width: 480px) {
  .body-content {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 768px) {
  .body-content {
    font-size: 20px;
    line-height: 28px;
  }

  .body-content h1.title {
    font-size: 40px;
    line-height: 46px;
  }

  .body-content h2 {
    font-size: 27px;
    line-height: 35px;
  }

  .body-content h3.subtitle {
    font-size: 20px;
    line-height: 28px;
  }
}

@media only screen and (min-width: 1300px) {
  .body-content {
    font-size: 19px;
    line-height: 25px;
  }

  .body-content h1.title {
    font-size: 40px;
    line-height: 46px;
  }

  .body-content h2 {
    font-size: 30px;
    line-height: 36px;
  }

  .body-content h3.subtitle {
    font-size: 20px;
    line-height: 28px;
  }
}
