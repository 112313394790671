@media only screen and (min-width: 320px) {
  .featured-content {
    margin-bottom: 30px;
  }

  .featured-content .title {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    font-family: "RalewayBold";
    line-height: normal;
  }

  .featured-content .subtitle {
    color: #666;
    font-size: 12px;
    font-style: italic;
    text-align: center;
    font-family: "RalewayItalic";
    line-height: normal;
  }

  .title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    line-height: 28px;
    font-family: "RalewayBold";
  }

  .subtitle {
    color: #666;
    font-size: 16px;
    font-style: italic;
    text-align: center;
    line-height: 22px;
    font-family: "RalewayItalic";
  }

  .teaser {
    color: #000;
    margin-top: 10px;
    text-align: justify;
  }

  .preview-text {
    padding: 10px 0;
  }

  .preview-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .preview-image img {
    width: 300px;
    height: 164px;
    border-radius: 10px;
  }

  .read-more {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }

  .featured h1 {
    font-size: 40px;
    line-height: 46px;
    font-weight: bold;
    font-family: "RalewayBold";
  }

  .featured h2 {
    font-size: 27px;
    line-height: 35px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: "RalewayBold";
    text-decoration: underline;
  }

  .featured hr {
    margin: 25px 0;
  }

  .carousel-container {
    margin-top: 15px;
  }

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    background-color: red;
    color: #fff;
    border-radius: 5px;
    width: 25px;
    height: 25px;
    position: absolute;
    top: 55%;
  }
}

@media only screen and (min-width: 480px) {
  .featured .title {
    font-size: 26px;
  }

  .featured-content .subtitle {
    font-size: 14px;
  }

  .featured h1.title {
    font-size: 40px;
    line-height: 46px;
  }

  .featured h2 {
    font-size: 27px;
    line-height: 35px;
  }

  .featured h3.subtitle {
    font-size: 20px;
    line-height: 28px;
  }

  .preview-image img {
    width: 420px;
    height: 230px;
    border-radius: 10px;
  }
}

@media only screen and (min-width: 768px) {
  .title {
    font-size: 48px;
    text-align: left;
    line-height: 60px;
  }

  .subtitle {
    font-size: 26px;
    text-align: left;
    line-height: 34px;
  }

  .featured-content .title {
    text-align: left;
  }

  .featured-content .subtitle {
    text-align: left;
  }

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    background-color: red !important;
    color: #fff !important;
    border-radius: 5px;
    width: 2rem;
    height: 2rem;
  }

  .preview-image img {
    width: 700px;
    height: 383px;
    border-radius: 10px;
  }
}

@media only screen and (min-width: 1200px) {
  .featured h1.title {
    font-size: 48px;
    line-height: 54px;
  }

  .featured h2 {
    font-size: 30px;
    line-height: 36px;
  }

  .featured h3.subtitle {
    font-size: 20px;
    line-height: 28px;
  }
}

@media only screen and (min-width: 1300px) {
  .featured-content {
    margin-bottom: 30px;
  }

  .featured-content .title {
    font-size: xx-large;
  }

  .featured-content .subtitle {
    font-size: 15px;
  }

  .teaser {
    color: #000;
    margin-top: 10px;
    text-align: justify;
  }

  .preview-text {
    padding: 5px 15px;
  }

  .preview-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .preview-image img {
    width: 200px;
    height: 200px;
  }

  .read-more {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
}

.emphasis {
  font-style: italic;
}

.carousel-indicators {
  background-color: rgba(51, 51, 51, 0.4);
  border-radius: 5px;
}

.carousel-bg {
  background-color: rgba(51, 51, 51, 0.8);
  padding: 5px 0;
  font-family: "RalewayBold";
  border-radius: 10px;
  color: #fff;
  font-weight: bold;
}

.emphasis-strong {
  font-style: italic;
  font-weight: bold;
}

.underline {
  text-decoration: line-through;
}

.row-border {
  border: 1px solid red;
}

.title > a {
  outline: none;
  text-decoration: none;
  color: inherit;
}

.title > a:hover {
  outline: none;
  color: #4da64d;
  transition: 0.3s;
}

.subtitle > a {
  outline: none;
  text-decoration: none;
  color: inherit;
}

.subtitle > a:hover {
  outline: none;
  color: #999999;
  transition: 0.3s;
}

.teaser > a {
  outline: none;
  text-decoration: none;
  color: inherit;
}

.teaser > a:hover {
  outline: none;
  text-decoration: none;
  color: #4da64d;
  transition: 0.3s;
}

.preview-container > a {
  opacity: 1;
}

.preview-container > a:hover {
  opacity: 0.7;
  transition: 0.3s;
}

.video {
  aspect-ratio: 16 / 9;
  width: 100%;
}
