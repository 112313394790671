@media only screen and (min-width: 320px) {
  .footer-container {
    bottom: 0;
    min-height: 100px;
    background-color: #000;
    padding: 20px 0;
    box-shadow: rgba(14, 30, 37, 0.12) 0px -5px 4px 0px,
      rgba(14, 30, 37, 0.32) 0px -5px 16px 0px;
  }
  .footer-text {
    color: #ccc;
    font-family: "RalewaySemiBold";
    font-weight: "bold";
    font-size: small;
  }

  ul.footer-links {
    list-style: none;
  }

  .footer-container a,
  ul.footer-links a:link {
    color: #ccc;
    text-decoration: none;
  }

  .footer-container a:hover,
  ul.footer-links a:hover {
    color: #666;
  }
}

@media only screen and (min-width: 414px) {
  .footer-text {
    font-size: medium;
  }
}

@media only screen and (min-width: 480px) {
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 1400px) {
  .footer-container {
    min-height: 250px;
  }
}
