html,
body {
  margin: 0px;
  height: 100%;
  font-family: "Raleway", Helvetica, Arial, sans-serif;
}

@media only screen and (min-width: 320px) {
  /* These are the top pages id container */
  #home,
  #articles,
  #dubious,
  #coming-soon,
  #about {
    margin-top: 20px;
    line-height: 1.5;
    padding: 0 15px;
    margin-bottom: 10px;
  }

  .responsive {
    width: 100%;
    max-width: 300px;
    height: auto;
    object-fit: cover;
  }

  #home p,
  #articles p,
  #dubious p,
  #about p {
    margin-top: 15px;
  }
}

@media only screen and (min-width: 480px) {
}

@media only screen and (min-width: 768px) {
  .responsive {
    width: 100%;
    max-width: 700px;
    height: auto;
  }
}

@media only screen and (min-width: 992px) {
  #home,
  #articles,
  #dubious,
  #about {
    margin-top: 30px;
    line-height: 1.6;
    padding: 0;
    border-bottom: 0;
  }
  #home p,
  #articles p,
  #dubious p,
  #about p {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 1400px) {
  #home,
  #articles,
  #dubious,
  #about {
    margin-top: 30px;
    line-height: 1.6;
  }

  .responsive {
    width: max-content;
    max-width: 100%;
    height: auto;
  }
}

blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";
}
blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}
blockquote p {
  display: inline;
}

.center-text {
  text-align: center !important;
}
