@media only screen and (min-width: 320px) {
  .sidebar-row-group {
    padding-top: 5px;
    padding-bottom: 15px;
  }

  .sidebar-header {
    font-size: 15px;
    font-weight: bold;
    padding-top: 0;
    padding-bottom: 5px;
    border-bottom: 1px solid #666;
    margin-bottom: 10px;
    font-family: "RalewayBold";
  }

  .sidebar-title {
    font-size: 13px;
    font-weight: bold;
    padding-top: 2px;
    padding-bottom: 2px;
    color: #0066b2;
  }

  .sidebar a {
    text-decoration: none;
    color: #0066b2;
  }

  .sidebar a:visited {
    color: #0066b2;
  }

  .sidebar a:hover {
    text-decoration: underline;
    color: #ccc;
    transition: 0.3s;
  }

  .affiliate-container {
    display: flex;
    justify-content: center;
  }
}
@media only screen and (min-width: 480px) {
}

@media only screen and (min-width: 768px) {
  .sidebar-header {
    font-size: 18px;
    font-weight: bold;
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #666;
    margin-bottom: 10px;
  }

  .sidebar-title {
    font-size: 15px;
    font-weight: bold;
    padding-top: 2px;
    padding-bottom: 2px;
    color: #0066b2;
  }

  .affiliate-container {
    display: flex;
    justify-content: left;
    padding-left: 20px;
  }
}

.row-separator {
  border-bottom: 1px solid #666;
}
