@media only screen and (min-width: 320px) {
  .header {
    height: 100px;
    background-color: #262626;
    padding-top: 20px;
    font-family: "Raleway", Helvetica, Arial, sans-serif;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  .header-title {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 2px;
    font-family: "RalewayBold", Helvetica, Arial, sans-serif;
    color: #fff;
  }

  .header-subtitle {
    font-family: "RalewaySemibold", Helvetica, Arial, sans-serif;
    font-size: 12px;
    color: #ccc;
  }
}

@media only screen and (min-width: 414px) {
  .header {
    padding-top: 10px;
    height: 100px;
    background-color: #262626;
    color: #fff;
  }
}

@media only screen and (min-width: 480px) {
  .header {
    font-size: 20px;
    height: 140px;
    background-color: #262626;
    color: #fff;
    padding-top: 20px;
  }

  .header-title {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 2px;
    padding-top: 20px;
  }

  .header-subtitle {
    font-size: 14px;
    color: #ccc;
  }
}

@media only screen and (min-width: 768px) {
  .header {
    height: 200px;
    background-color: #262626;
    color: #fff;
    padding-top: 0;
  }

  .header-title {
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 2px;
    padding-top: 25px;
  }

  .header-subtitle {
    font-size: large;
    color: #ccc;
  }
}

@media only screen and (min-width: 1400px) {
  .header {
    height: 200px;
    background-color: #262626;
    color: #fff;
    padding-top: 10px;
  }

  .header-title {
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 2px;
    padding-top: 25px;
  }

  .header-subtitle {
    font-size: large;
    color: #ccc;
  }
}

.row-border {
  text-align: center;
}

.menu-row {
  border: 1px solid red;
}

.color-nav {
  background-color: #262626 !important;
}
